import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'react-bootstrap';
import ExternalLink from './externalLink';

const Welcome = ({ siteTitle, classes, hasArrow }) => (
  <section id='welcome' className={classes}>
    <Container>
      <Row>
        <Col className='center-flex' xs='7' sm='7' md='7' lg='7'>
          <hr className='horizontalRule' />
          <h2>
            {' '}
            Supporting Communities, Transforming Maternal Health: A Spotlight on the Safer Childbirth Cities Initiative  {' '}
          </h2>
          <p>We are excited to announce the publication of "Supporting Communities, Transforming Maternal Health: A Spotlight on the Safer Childbirth Cities Initiative" evaluation report by Black Mamas Matter Alliance (BMMA). This report delves into the achievements of SCC grantees, highlighting effective community-led solutions and catalyzing increased investments in maternal health innovation. Learn how SCC grantees have provided care to thousands and contributed to major policy wins in maternal health.</p>
        </Col>
        <Col className='center-flex justify-center' xs='5' sm='5' md='5' lg='5'>
          {/* <ExternalLink href='https://www.merckformothers.com/new-report-shows-progress-in-maternal-health-care-through-safer-childbirth-cities-initiative.html'>
            <button className='btn btn-primary large center-block'>
              LEARN MORE
            </button>
          </ExternalLink> */}
          <a href='https://www.merckformothers.com/new-report-shows-progress-in-maternal-health-care-through-safer-childbirth-cities-initiative.html' target='_blank'>
            <button className='btn btn-primary large center-block'>
              LEARN MORE
            </button>
          </a>
        </Col>
      </Row>
    </Container>
    {hasArrow ? <div className='arrow-down'></div> : ''}
  </section>
);

Welcome.propTypes = {
  siteTitle: PropTypes.string.isRequired
};

Welcome.defaultProps = {
  siteTitle: ``
};

export default Welcome;
